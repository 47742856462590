// Our Footer component
// Thsi will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Footer() {
  return (
    <section className="footer">
      <a href="#home" className="link-block w-inline-block">
        <img src="images/arrow-right.png" loading="lazy" alt="" className="image-7" />
        <div className="text-block-5">Back to Top</div>
      </a>
    </section>
  );
}

export default Footer;
