import React from "react";

const Gallery1xn = ({ gallery }) => {
  if (!gallery) return null;
  return (
    <div class="gallery1xn">
      {gallery?.map((el, i) => (
        <img key={i} src={el.attributes.url} loading="lazy" class="galleryimage" />
      ))}
    </div>
  );
};

export default Gallery1xn;
