import React from "react";
import Page from "../components/Page";
import Hero from "../sections/Hero";
import About from "../sections/About";
import Projects from "../sections/Projects";
import Certifications from "../sections/Certifications";
import Testimonials from "../sections/Testimonials";
import Resume from "../sections/Resume";
import Contact from "../sections/Contact";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import RichText from "../components/RichText";
import Gallery2x2 from "../components/Gallery2x2";
import Gallery1xn from "../components/Gallery1xn";

function ProjectDetailPage() {
  // access our data so that we can display it
  const collectionTypeSlug = "projects";
  const { data } = useSelector((state) => state.cms);
  const { id } = useParams();
  const item = data[collectionTypeSlug]?.find((el) => el.id == id)?.attributes;

  // only display the component if our data has been loaded
  if (!item) return <LoadingPage />;

  return (
    <Page>
      <div className="page-content">
        <section id="about" class="section">
          <div class="w-layout-blockcontainer container w-container">
            <h1 class="center light">{item.name}</h1>
            <div class="div-block-14">
              {item.figma && (
                <a class="div-block-15" href={item.figma} target="_blank" rel="noreferrer">
                  <img src="images/Figma-1.png" loading="lazy" alt="" class="figma" />
                  <div class="text-block-7">Access Design Files on Figma</div>
                </a>
              )}

              {item.link && (
                <a class="div-block-15" href={item.link} target="_blank" rel="noreferrer">
                  <img src="images/Figma.png" loading="lazy" alt="" class="figma" />
                  <div class="text-block-7">Checkout the Final Product</div>
                </a>
              )}
            </div>
          </div>
        </section>{" "}
        {item?.subPageSections.map((el, i) => {
          return <SubSection el={el} i={i} key={i} />;
        })}
        <Footer />
      </div>
    </Page>
  );
}

export default ProjectDetailPage;

const SubSection = ({ el, i }) => {
  const renderContent = () => {
    switch (el.__component) {
      case "project.gallery-2x2":
        return <Gallery2x2 gallery={el.gallery?.data} />;
      case "project.gallery-full-width":
        return <Gallery1xn gallery={el.gallery?.data} />;

      default:
        return null;
    }
  };
  return (
    <section id="projects" class="section">
      <div class="w-layout-blockcontainer container w-container">
        <div class="div-block-16">
          <div class="text-block-8">{i + 1}</div>
          <h2 class="small">{el.heading}</h2>
        </div>

        <RichText text={el.body} />

        {renderContent()}
      </div>
    </section>
  );
};
