import React from "react";
import { v4 as uuid } from "uuid";

const Hero = ({ data }) => {
  return (
    <section
      id="home"
      className="section hero"
      style={{
        backgroundImage: `radial-gradient(circle at 100% 0, rgba(203, 157, 6, .06), rgba(255, 255, 255, 0)), radial-gradient(circle at 0%, rgba(0, 0, 0, .45), rgba(0, 0, 0, 0)), url(${data.background?.data?.attributes?.url})`,
      }}
    >
      <div className="w-layout-blockcontainer container hero w-container">
        <h1 className="shtxt hero-name">{data.heading}</h1>
        <p className="shtxt hero-description">{data.subheading}</p>
        <ul role="list" className="shtxt hero-list">
          {data.listItems.map((item) => (
            <li key={uuid()} className="hero-list-item">
              {item.value}
            </li>
          ))}
        </ul>
        {data.CTA && (
          <a href={data.CTA.href} className={`button  hero-btn sh2 w-button ${data?.CTA?.style || "secondary"}`}>
            {data.CTA.text}
          </a>
        )}
      </div>
    </section>
  );
};

export default Hero;
