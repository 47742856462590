// RICH TEXT!
import React, { useState } from "react";

import RichText from "../components/RichText";
const Testimonials = ({ data }) => {
  const [index, setIndex] = useState(0);

  let active = data?.testimonials[index];

  const stepIndex = (val) => {
    let tmp = index + val;
    if (tmp >= data?.testimonials?.length) {
      setIndex(0);
    } else if (tmp < 0) {
      setIndex(data?.testimonials?.length - 1);
    } else {
      setIndex(tmp);
    }
  };

  return (
    <section id="testimonials" className="section">
      <div className="w-layout-blockcontainer container w-container">
        <h2>{data?.heading}</h2>
        <div className="sub-heading left">{data?.subheading}</div>
        <div className="div-block-8">
          <div className="w-layout-grid grid-3 sh1">
            <div id="w-node-_381a2c20-c1a7-e512-22d0-4470f1ab30f8-db383e9f" className="div-block-7">
              <RichText text={active.body} className="paragraph" />

              <div className="text-block-4">
                {active.name} |{" "}
                <a href={active.companyLink} target="_blank" className="link">
                  {active.company}
                </a>
              </div>
            </div>

            <div id="w-node-_64269b26-9ad1-21c3-4624-a4a064d0ea1c-db383e9f" className="div-block-12">
              <div id="w-node-ecccca6a-4314-c5ae-1e93-f5d746b96de1-db383e9f" className="html-embed-2 w-embed">
                <style
                  dangerouslySetInnerHTML={{
                    __html: `\n                  
                    .angledImg {
                      \n
                    background: url(${active?.image?.data?.attributes?.url});\n
                    background-repeat: no-repeat;\n
                    border-radius: 12px;\n
                    background-size: contain;\n
                    background-color: #f0f0f0;\n
                    background-position: center;\n
                    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);\n
                    width: 100%;\n
                    height: 100%;\n
                  }\n
                  .flt_svg {\n
                    visibility: hidden;\n
                    position: absolute;\n
                    width: 0px;\n
                    height: 0px;\n
                  }\n
                  .box_parent {\n
                    filter: url("#flt_tag");\n
                    height: 100%;\n
                  }\n
                `,
                  }}
                />
                <div className="box_parent">
                  <div className="angledImg" />
                  <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <filter id="flt_tag">
                        <feGaussianBlur in="SourceGraphic">
                          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag">
                            <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                          </feColorMatrix>
                        </feGaussianBlur>
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="project-arrow" onClick={() => stepIndex(1)}>
            <img src="images/arrow-right.png" loading="lazy" alt="" className="arrow" />
          </div>
          <div className="project-arrow back" onClick={() => stepIndex(-1)}>
            <img src="images/arrow-left.png" loading="lazy" alt="" className="arrow back" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
