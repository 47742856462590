// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "landing-page",
    populate: qs.stringify({
      populate: {
        navbar: {
          populate: {
            headshot: {
              populate: "*",
            },
          },
        },
        hero: {
          populate: {
            listItems: {
              populate: "*",
            },
            CTA: {
              populate: "*",
            },
            background: {
              populate: "*",
            },
          },
        },
        about: {
          populate: "*",
        },
        projects: {
          populate: {
            projects: {
              populate: {
                project: {
                  populate: "*",
                },
              },
            },
          },
        },
        certifications: {
          populate: {
            certifications: {
              populate: {
                certification: {
                  populate: "*",
                },
              },
            },
            background: {
              populate: "*",
            },
          },
        },
        testimonials: {
          populate: {
            testimonials: {
              populate: {
                image: {
                  populate: "*",
                },
              },
            },
          },
        },
        resume: {
          populate: {
            background: {
              populate: "*",
            },
            resume: {
              populate: "*",
            },
          },
        },
        contact: {
          populate: {
            submit: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "projects",
    populate: qs.stringify({
      populate: {
        coverPhoto: {
          populate: "*",
        },
        subPageSections: {
          populate: "*",
        },
      },
    }),
  },
];

export const strapiAuthenticatedEndpoints = [];

// An example for getting complicated with the populate feild:
//   populate: qs.stringify({
//     populate: {
//       exampleSlider: {
//         populate: {
//           items: {
//             populate: "background",
//           },
//         },
//       },
//       exampleAccordion: {
//         populate: ["items"],
//       },
//     },
//   }),
