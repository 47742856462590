import React from "react";

import { v4 as uuid } from "uuid";
const Resume = ({ data }) => {
  return (
    <section id="resume" className="section resume">
      <div className="w-layout-blockcontainer container resume w-container">
        <h2 className="center">{data.heading}</h2>
        <div className="sub-heading">{data.subheading}</div>
        <div className="div-block-5 sh1" style={{ backgroundImage: `url(${data.background?.data?.attributes?.url})` }}>
          <a className="download" href={data?.resume?.data?.attributes?.url} target="_blank">
            <div className="download-icon w-embed">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.44 8.9C20.04 9.21 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24 7.46998 8.91"
                  stroke="CurrentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M12 2V14.88" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.3499 12.65L11.9999 16L8.6499 12.65" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className="text-block-3">{data.button}</div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
