import React from "react";
import { useSubmitForm } from "../hooks/useSubmitForm";

const Contact = ({ data }) => {
  const { handleSubmit } = useSubmitForm();

  return (
    <section id="contact" className="section">
      <div className="w-layout-blockcontainer container form w-container">
        <h2 className="center">{data.heading}</h2>
        <div className="sub-heading">{data.subheading}</div>
        <div className="form-block w-form">
          <form
            onSubmit={handleSubmit}
            id="email-form"
            name="email-form"
            data-name="Email Form"
            method="get"
            data-wf-page-id="64dd157813120d96db383e9f"
            data-wf-element-id="bca97418-ebbc-2f0b-7c95-2b2d787f7b78"
          >
            <div className="form-row">
              <input type="text" className="text-field w-input" maxLength={256} name="Name" data-name="Name" placeholder="Name" id="name" />
              <input type="email" className="text-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Email" id="email" required="" />
            </div>
            <textarea placeholder="How can I help you?" maxLength={5000} id="Message" name="Message" data-name="Message" className="text-field text-area w-input" defaultValue={""} />
            <div className="div-block-4">
              <input type="submit" value={data?.submit?.text || "Send →"} className={`button ${data?.submit?.style || "primary"} form-submit sh1 w-button`} />
            </div>
          </form>
          <div className="success sh1 w-form-done">
            <h3>Thank You!</h3>
            <div>Your submission has been received.</div>
          </div>
          <div className="error sh1 w-form-fail">
            <h3>Ooops!</h3>
            <div>Something went wrong... Please email aidan.mustoe@gmail.com</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
