import React from "react";
import Page from "../components/Page";
import Hero from "../sections/Hero";
import About from "../sections/About";
import Projects from "../sections/Projects";
import Certifications from "../sections/Certifications";
import Testimonials from "../sections/Testimonials";
import Resume from "../sections/Resume";
import Contact from "../sections/Contact";

// Import all the functions we need
import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import Footer from "../components/Footer";

function LandingPage() {
  // access our data so that we can display it
  const singleTypeSlug = "landing-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <Page>
      <div className="page-content">
        <Hero data={cmsData.hero} />
        <About data={cmsData.about} />
        <Projects data={cmsData.projects} />

        <Certifications data={cmsData.certifications} />
        <Testimonials data={cmsData.testimonials} />
        <Resume data={cmsData.resume} />
        <Contact data={cmsData.contact} />

        <Footer />
      </div>
    </Page>
  );
}

export default LandingPage;
