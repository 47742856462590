// DOCUMENTATION:
// https://reactrouter.com/docs/en/v6

// This file sets up the page navigation or the sitemap of our website.
// This is not our Navbar component

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

// Import the necessary page files from ../pages/Something.js
import LandingPage from "../pages/LandingPage";
import ErrorPage from "../pages/ErrorPage";
import ProjectDetailPage from "../pages/ProjectDetailPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* We need to add a route for each of our pages. 
          Path is the page slug www.something.com/slug. 
          Element is the React component to display when we get to that slug. */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/projects/:id" element={<ProjectDetailPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;
