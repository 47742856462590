import React from "react";

const Gallery2x2 = ({ gallery }) => {
  if (!gallery) return null;
  return (
    <div class="gallery2xn">
      {gallery?.map((el, i) => (
        <img key={i} src={el.attributes.url} loading="lazy" class="galleryimage" style={{ height: "300px" }} />
      ))}
    </div>
  );
};

export default Gallery2x2;
