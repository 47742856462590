import React from "react";
import { v4 as uuid } from "uuid";
import RichText from "../components/RichText";

const About = ({ data }) => {
  return (
    <section id="about" className="section">
      <div className="w-layout-blockcontainer container about w-container">
        <h2 className="center">{data.heading}</h2>
        <RichText className="about" text={data.body} />
      </div>
    </section>
  );
};

export default About;
