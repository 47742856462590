// Our Navbar component
// This will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import LoadingPage from "../pages/LoadingPage";

function Navbar() {
  const singleTypeSlug = "landing-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <div className="navbar">
      <div className="nav-headshot">
        <img src={cmsData.navbar?.headshot?.data?.attributes?.url || "images/microsoft-365-TLiWhlDEJwA-unsplash-min.jpg"} loading="lazy" alt="" className="image-2" />
      </div>
      <div className="div-block-3">
        <div className="nav-name">{cmsData.navbar?.name}</div>
      </div>

      <div className="nav-links">
        <a href="/#home" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 18V15" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M10.07 2.82L3.13999 8.37C2.35999 8.99 1.85999 10.3 2.02999 11.28L3.35999 19.24C3.59999 20.66 4.95999 21.81 6.39999 21.81H17.6C19.03 21.81 20.4 20.65 20.64 19.24L21.97 11.28C22.13 10.3 21.63 8.99 20.86 8.37L13.93 2.83C12.86 1.97 11.13 1.97 10.07 2.82Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="nav-link-text">Home</div>
        </a>
        <a href="/#about" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="nav-link-text">About</div>
        </a>
        <a href="/#projects" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 9V16" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M5.25 8.5C7.04493 8.5 8.5 7.04493 8.5 5.25C8.5 3.45507 7.04493 2 5.25 2C3.45507 2 2 3.45507 2 5.25C2 7.04493 3.45507 8.5 5.25 8.5Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 22C6.65685 22 8 20.6569 8 19C8 17.3431 6.65685 16 5 16C3.34315 16 2 17.3431 2 19C2 20.6569 3.34315 22 5 22Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19 22C20.6569 22 22 20.6569 22 19C22 17.3431 20.6569 16 19 16C17.3431 16 16 17.3431 16 19C16 20.6569 17.3431 22 19 22Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M5.13 9C5.58 10.75 7.18 12.05 9.07 12.04L12.5 12.03C15.12 12.02 17.35 13.7 18.17 16.04" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="nav-link-text">Projects</div>
        </a>
        <a href="/#certifications" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.05 2.53L4.03002 6.46C2.10002 7.72 2.10002 10.54 4.03002 11.8L10.05 15.73C11.13 16.44 12.91 16.44 13.99 15.73L19.98 11.8C21.9 10.54 21.9 7.73 19.98 6.47L13.99 2.54C12.91 1.82 11.13 1.82 10.05 2.53Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.63 13.08L5.62 17.77C5.62 19.04 6.6 20.4 7.8 20.8L10.99 21.86C11.54 22.04 12.45 22.04 13.01 21.86L16.2 20.8C17.4 20.4 18.38 19.04 18.38 17.77V13.13"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M21.4 15V9" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="nav-link-text">Certifications</div>
        </a>
        <a href="/#testimonials" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.38 12L10.79 14.42L15.62 9.57999" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M10.75 2.45001C11.44 1.86001 12.57 1.86001 13.27 2.45001L14.85 3.81001C15.15 4.07001 15.71 4.28001 16.11 4.28001H17.81C18.87 4.28001 19.74 5.15001 19.74 6.21001V7.91001C19.74 8.30001 19.95 8.87001 20.21 9.17001L21.57 10.75C22.16 11.44 22.16 12.57 21.57 13.27L20.21 14.85C19.95 15.15 19.74 15.71 19.74 16.11V17.81C19.74 18.87 18.87 19.74 17.81 19.74H16.11C15.72 19.74 15.15 19.95 14.85 20.21L13.27 21.57C12.58 22.16 11.45 22.16 10.75 21.57L9.17 20.21C8.87 19.95 8.31 19.74 7.91 19.74H6.18C5.12 19.74 4.25 18.87 4.25 17.81V16.1C4.25 15.71 4.04 15.15 3.79 14.85L2.44 13.26C1.86 12.57 1.86 11.45 2.44 10.76L3.79 9.17001C4.04 8.87001 4.25 8.31001 4.25 7.92001V6.20001C4.25 5.14001 5.12 4.27001 6.18 4.27001H7.91C8.3 4.27001 8.87 4.06001 9.17 3.80001L10.75 2.45001Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="nav-link-text">Testimonials</div>
        </a>
        <a href="/#resume" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7 13H13" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7 17H11" stroke="CurrentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="nav-link-text">Resume</div>
        </a>
        <a href="/#contact" className="nav-link w-inline-block">
          <div className="nav-link-icon w-embed">
            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M15.9965 11H16.0054" stroke="CurrentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.9955 11H12.0045" stroke="CurrentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.99451 11H8.00349" stroke="CurrentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="nav-link-text">Contact</div>
        </a>
      </div>
      <div className="nav-footer">
        <div className="copyright">
          ©{" "}
          <a href="/#home" className="link-accent-2 copyright">
            Aidan Mustoe
          </a>
          , <br />
          All Rights Reserved
        </div>
      </div>
      <div data-w-id="58895f8e-715d-6ea7-dff3-7ffc495d8eb1" className="nav-toggle">
        <img src="/images/arrow-left.png" loading="lazy" alt="" className="nav-toggle-img" />
      </div>
    </div>
  );
}

export default Navbar;
